<template>
  <div :class="{ 'modal-open': showModal }">
    <div class="my-app home-two-dark">
      <Header2 class="header--dark" />
      <MobileMenu />
      <section class="section team-main pb-0">
        <div
          class="min-h-screen flex flex-col justify-center items-center px-4 py-4"
        >
          <div class="button-container mb-4">
            <button @click="openModal('create')" class="btn-one">
              {{ labelsState["appointment_new"] || "New Appointment" }}
            </button>
            <button @click="openModal('edit')" class="btn-one">
              {{ labelsState["appointment_change"] || "Change Appointment" }}
            </button>
            <button @click="openModal('delete')" class="btn-one">
              {{ labelsState["appointment_cancel"] || "Cancel Appointment" }}
            </button>
          </div>
          <div class="container">
            <p class="text-xl">
              {{
                labelsState["appointment_calendar"] || "Appointment Calendar"
              }}
            </p>
            <div class="row items-gap align-items-center">
              <p class="fw-5 text-md">
                {{
                  labelsState["appoint_message"] ||
                  "Welcome to our appointment system. We invite you to select your preferred time slot. It will be our pleasure to assist you!"
                }}
              </p>
              <div
                class="team-main-single wow fadeInLeft"
                data-wow-duration="600ms"
                data-wow-delay="300ms"
              >
                <div class="flex justify-center m-4 rounded calendar-container">
                  <iframe
                    :src="calendarUrl"
                    class="rounded calendar-iframe"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>

          <div v-if="showModal" class="modal-overlay" @click.self="closeModal">
            <div class="modal-popup">
              <h2 class="modal-header">{{ modalTitle }}</h2>
              <div class="modal-body">
                <form
                  @submit.prevent="handleSubmit"
                  class="flex flex-col gap-3"
                >
                  <div
                    v-if="modalAction === 'create'"
                    class="flex flex-col gap-4"
                  >
                    <p class="modal-info-text">
                      {{
                        labelsState["appointment_create"] ||
                        "To schedule your appointment, select an available date and time according to the main calendar, complete your details and confirm your reservation, remember between 12 PM and 1 PM Colombia time the team is not available."
                      }}
                    </p>
                    <div class="form-group">
                      <label for="user_name">{{
                        labelsState["user_name"] || "User Name"
                      }}</label>
                      <input
                        v-model="formDataResult.user_name"
                        type="text"
                        id="user_name"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label for="user_email">{{
                        labelsState["user_email"] || "User Email"
                      }}</label>
                      <input
                        v-model="formDataResult.user_email"
                        type="email"
                        id="user_email"
                        required
                      />
                    </div>

                    <div class="form-group">
                      <label for="start_time">{{
                        labelsState["time_colombia"] ||
                        "Date and Time (Colombia Time)"
                      }}</label>

                    <div>
                      <DatePicker
                        v-model="formDataResult.start_time"
                        type="datetime"
                        format="YYYY-MM-DD HH:mm"
                        :disabled-date="disabledDate"
                        :time-picker-options="timePickerOptions"
                        :minute-step="30"
                        :placeholder="labelsState['time_colombia_date'] || 'Date and Time (Colombia Time)'"
                        class="date-picker"
                        @change="checkAvailability"
                      />
                    </div>

                    </div>
                    <div class="button-container p-2 mx-5">
                      <button
                        type="submit"
                        class="btn add-button montserrat-discontinuo"
                      >
                        {{ labelsState["save"] || "Save" }}
                      </button>
                      <button
                        type="button"
                        class="btn custom-button montserrat-discontinuo"
                        @click="closeModal"
                      >
                        {{ labelsState["cancel"] || "Cancel" }}
                      </button>
                    </div>
                  </div>

                  <div v-else class="flex flex-col gap-4">
                    <div v-if="!isQueryDone" class="flex flex-col gap-4">
                      <p class="modal-info-text">
                        {{
                          labelsState["appointment_reference"] ||
                          "Enter your reference number and email to retrieve or manage your appointment."
                        }}
                      </p>
                      <div class="form-group">
                        <label for="reference">{{
                          labelsState["reference"] || "Reference Number"
                        }}</label>
                        <input
                          v-model="formDataFetch.reference"
                          type="text"
                          id="reference"
                          required
                        />
                      </div>
                      <div class="form-group">
                        <label for="email">{{
                          labelsState["user_email"] || "User Email"
                        }}</label>
                        <input
                          v-model="formDataFetch.user_email"
                          type="email"
                          id="email"
                          required
                        />
                      </div>

                      <div class="button-container p-2 mx-5">
                        <button
                          type="button"
                          class="btn add-button montserrat-discontinuo"
                          @click="fetchAppointmentDetails"
                        >
                          {{ labelsState["consult"] || "Consult" }}
                        </button>
                        <button
                          type="button"
                          class="btn custom-button montserrat-discontinuo"
                          @click="closeModal"
                        >
                          {{ labelsState["cancel"] || "Cancel" }}
                        </button>
                      </div>
                    </div>

                    <template v-if="isQueryDone">
                      <p v-if="modalAction === 'edit'" class="modal-info-text">
                        {{
                          labelsState["appointment_confirm_edit"] ||
                          "The following appointment is selected for editing:"
                        }}
                      </p>
                      <p
                        v-if="modalAction === 'delete'"
                        class="modal-info-text"
                      >
                        {{
                          labelsState["confirm_delete"] ||
                          "The following appointment is scheduled to be canceled:"
                        }}
                      </p>
                      <div class="form-group">
                        <label>{{
                          labelsState["reference"] || "Reference"
                        }}</label>
                        <p>{{ formDataResult.reference }}</p>
                      </div>
                      <div class="form-group">
                        <label>{{ labelsState["details"] || "Details" }}</label>
                        <p>{{ formDataResult.description }}</p>
                      </div>
                      <div class="form-group">
                        <label>{{
                          labelsState["time_colombia"] ||
                          "Date and Time (Colombia Time)"
                        }}</label>
                        <p>
                          {{
                            formatDateToColombiaTime(formDataResult.start_time)
                          }}
                        </p>
                      </div>

                      <div v-if="modalAction === 'edit'">

                        <div class="form-group">
                          <label for="start_time">{{
                            labelsState["time_colombia"] ||
                            "Date and Time (Colombia Time)"
                          }}</label>

                      <div>
                        <DatePicker
                          v-model="formDataResult.start_time"
                          type="datetime"
                          format="YYYY-MM-DD HH:mm"
                          :disabled-date="disabledDate"
                          :time-picker-options="timePickerOptions"
                          :minute-step="30"
                          placeholder="Selecciona fecha y hora"
                          class="date-picker"
                          @change="checkAvailability"
                        />
                      </div>

                        </div>
                        <div class="button-container p-2 mx-5">
                          <button
                            type="submit"
                            class="btn add-button montserrat-discontinuo"
                          >
                            {{ labelsState["save"] || "Save" }}
                          </button>
                          <button
                            type="button"
                            class="btn custom-button montserrat-discontinuo"
                            @click="closeModal"
                          >
                            {{ labelsState["cancel"] || "Cancel" }}
                          </button>
                        </div>
                      </div>

                      <div v-if="modalAction === 'delete'">
                        <div class="button-container p-2 mx-5">
                          <button
                            type="submit"
                            class="btn add-button montserrat-discontinuo"
                            @click="deleteAppointment"
                          >
                            {{
                              labelsState["cancel_appoint"] ||
                              "Cancel Appointment"
                            }}
                          </button>
                          <button
                            type="button"
                            class="btn custom-button montserrat-discontinuo"
                            @click="closeModal"
                          >
                            {{
                              labelsState["keep_appoint"] || "Keep Appointment"
                            }}
                          </button>
                        </div>
                      </div>
                    </template>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </div>
    <BackToTop />
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { computed, ref } from "vue";
import { useLabelsStore } from "@/stores/labels";
import { useLanguageStore } from "@/stores/language";
import "../assets/css/global.css";
import axios from "axios";
import Swal from "sweetalert2";
import Header2 from "../components/layout/Header2.vue";
import MobileMenu from "../components/layout/MobileMenu.vue";
import Footer2 from "../components/layout/Footer2.vue";
import BackToTop from "../components/common/BackToTop.vue";

export default {
  name: "CalendarSection",
  components: { Header2, MobileMenu, Footer2, BackToTop,DatePicker  },
  setup() {
    const disabledDate = (date) => {
      const day = date.getDay();
      return day === 0 || day === 6;
    };
    const timePickerOptions = {
      start: "09:00",
      end: "18:00",
      step: "00:30", 
    };

    const labelsStore = useLabelsStore();
    const labelsState = computed(() => labelsStore.labels);
    const showModal = ref(false);
    const modalAction = ref("");
    const calendarUrl = "https://outlook.office365.com/calendar/published/555782de592e4e2883de34cf37ca7158@nqartificial.com/1406292f11cd465ba8725f001e5b68bc10525054965135746279/calendar.html"
    labelsState.value["calendar_link"] ||
      "https://outlook.office365.com/calendar/published/555782de592e4e2883de34cf37ca7158@nqartificial.com/1406292f11cd465ba8725f001e5b68bc10525054965135746279/calendar.html";
    const isQueryDone = ref(false);
    const formDataResult = ref({
      user_email: "",
      user_name: "",
      start_time: "",
      description: "",
      reference: "",
    });
    const formDataFetch = ref({ reference: "", user_email: "" });
    function formatDateToColombiaTime(dateString) {
      const utcDate = new Date(dateString);
      return new Intl.DateTimeFormat("es-CO", {
        timeZone: "America/Bogota",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZoneName: "short",
      }).format(utcDate);
    }
    function formatDateToDatetimeLocal(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

    const modalTitle = computed(() => {
    return modalAction.value === "create"
    ? labelsState.value["appointment_new"] || "New Appointment"
    : modalAction.value === "edit"
    ? labelsState.value["appointment_change"] || "Change Appointment"
    : labelsState.value["cancel_appoint"] || "Cancel Appointment";
});

    const openModal = (action) => {
      modalAction.value = action;
      showModal.value = true;
      resetForm();
    };
    const closeModal = () => {
      showModal.value = false;
      resetForm();
    };
    const resetForm = () => {
      formDataResult.value = {
        user_email: "",
        user_name: "",
        start_time: "",
        description: "",
        reference: "",
      };
      formDataFetch.value = { reference: "", user_email: "" };
    };
    const handleSubmit = () => {
      modalAction.value === "create"
        ? createAppointment()
        : modalAction.value === "edit"
        ? editAppointment()
        : deleteAppointment();
      closeModal();
    };
    const fetchAppointmentDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_HOST_WHATSAPP}/appointment/user/${formDataFetch.value.reference}/${formDataFetch.value.user_email}`
        );
        if (response.status === 200) {
          const { reference, description, start_time } = response.data.data;
          formDataResult.value = {
            reference,
            description,
            start_time: formatDateToDatetimeLocal(start_time),
          };
          formDataResult.value.user_email = formDataFetch.value.user_email;
        }
        isQueryDone.value = true;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: labelsState["error"] || "Error",
          text:
            error.response?.data?.error ||
            labelsState["error_detail"] || "Failed to fetch details.",
        });
      }
    };
    const deleteAppointment = async () => {
      const reference = formDataResult.value.reference;
      const user_email = formDataResult.value.user_email;
      const result = await Swal.fire({
        title: labelsState["sure"] || "Are you sure?",
        text: labelsState["confirm_delete"] || "This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText:
          labelsState["cancel_appoint"] || "Cancel Appointment",
        cancelButtonText: labelsState["keep_appoint"] || "Keep Appointment",
        padding: "2em",
        customClass: {
          confirmButton: "btn add-button montserrat-discontinuo",
          cancelButton: "btn custom-button montserrat-discontinuo",
          actions: "swal2-buttons",
        },
        buttonsStyling: false,
      });

      if (result.isConfirmed) {
        try {
          await axios.delete(
            `${process.env.VUE_APP_HOST_WHATSAPP}/appointment/delete/${reference}/${user_email}`
          );
          Swal.fire({ icon: "success", title: "Appointment Deleted" });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: labelsState["error"] || "Error",
            text:
              error.response?.data?.error || "Failed to delete appointment.",
          });
        }
      }
    };
    const editAppointment = async () => {
      try {
        const localDateTime = new Date(`${formDataResult.value.start_time}:00`);
        await axios.put(
          `${process.env.VUE_APP_HOST_WHATSAPP}/appointment/modify`,
          {
            user_email: formDataResult.value.user_email,
            reference: formDataResult.value.reference,
            new_start_time: localDateTime.toISOString(),
            new_description: formDataResult.value.description,
          },
          { headers: { "Content-Type": "application/json" } }
        );
        Swal.fire({
          icon: "success",
          title:
            labelsState["success_update_appointment"] ||
            "Appointment Changed Successfully",
          text:
            labelsState["copy_number_reference"] ||
            "Your reference number is ready to be copied. This number will allow you to manage your scheduled appointment.",
          showCloseButton: true,
          confirmButtonText: labelsState["copy"] || "Copy",
          customClass: {
            confirmButton: "btn add-button montserrat-discontinuo",
            actions: "swal2-buttons",
          },
          buttonsStyling: false,
          didOpen: () => {
            Swal.getConfirmButton().addEventListener("click", () => {
              navigator.clipboard.writeText(response.data.reference);
              Swal.fire(
                labelsState["copied"] || "Copied!",
                labelsState["copied_reference"] ||
                  "Reference number copied to clipboard",
                "success"
              );
            });
          },
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: labelsState["error"] || "Error",
          text: error.response?.data?.error || "Failed to update appointment.",
        });
      }
    };

    const createAppointment = async () => {
      try {
        const localDateTime = new Date(`${formDataResult.value.start_time}:00`);
        const response = await axios.post(
          `${process.env.VUE_APP_HOST_WHATSAPP}/appointment/create`,
          {
            user_email: formDataResult.value.user_email,
            user_name: formDataResult.value.user_name,
            start_time: localDateTime.toISOString(),
            description: formDataResult.value.description,
          },
          { headers: { "Content-Type": "application/json" } }
        );

          const referenceNumber = response.data.reference;  
 

        Swal.fire({
          icon: "success",
          title:
            labelsState["success_create_appointment"] ||
            "Appointment Created Successfully",
            text:   `${
              labelsState["copy_number_reference"] ||
              "Your appointment was successfully created. Please save this reference, this number will allow you to manage your scheduled appointment."
            }\n\nReference Number: ${referenceNumber}\n\n`,
            
          showCloseButton: true,
          confirmButtonText: labelsState["copy"] || "Copy",
          customClass: {
            confirmButton: "btn add-button montserrat-discontinuo",
            actions: "swal2-buttons",
          },
          buttonsStyling: false,
          didOpen: () => {
            Swal.getConfirmButton().addEventListener("click", () => {
              navigator.clipboard.writeText(response.data.reference);
              Swal.fire(
                labelsState["copied"] || "Copied!",
                labelsState["copied_reference"] ||
                  "Reference number copied to clipboard",
                "success"
              );
            });
          },
        });
      } 
      
      catch (error) {

        if (
          error.response &&
          error.response.data.error === "Appointment slot is already booked"
        ) 
    {
      Swal.fire({
        icon: "error",
        title: labelsState["error"] || "Error",
        text:
          labelsState["slot_unavailable"] ||
          "The selected time is not available. Please select another time based on the availability of the main calendar. Remember between 12 PM and 1 PM the team is not available.",
        confirmButtonText: labelsState["ok"] || "OK",
        customClass: {
          confirmButton: "btn add-button montserrat-discontinuo",
          actions: "swal2-buttons",
        },
        buttonsStyling: false,
      });

    } else {      
      Swal.fire({
        icon: "error",
        title: labelsState["error"] || "Error",
        text:
          error.response?.data?.error ||
          labelsState["error_add_appointment"] ||
          "An error occurred while creating the appointment.",
        confirmButtonText: labelsState["ok"] || "OK",
        customClass: {
          confirmButton: "btn add-button montserrat-discontinuo",
          actions: "swal2-buttons",
        },
        buttonsStyling: false,
      });
    }
  }
};

    return {
      labelsState,
      calendarUrl,
      showModal,
      modalAction,
      isQueryDone,
      formDataResult,
      formDataFetch,
      modalTitle,
      openModal,
      closeModal,
      handleSubmit,
      createAppointment,
      editAppointment,
      deleteAppointment,
      fetchAppointmentDetails,
      formatDateToColombiaTime,
      disabledDate,
      timePickerOptions,
      formDataResult,
    };
  },
};
</script>

<style scoped>

.calendar-container {
  width: 100%;
  height: 650px;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  margin: 2px;
  background: #192337;
}
.calendar-iframe {
  width: 100%;
  height: 100%;
  border: 2px solid #111827;
  transform: scale(0.87);
}


.button-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.btn-one {
  padding: 10px 20px;
  border: 2px solid #fff;
  border-radius: 50px;
  background-color: transparent;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 120px; 
  text-align: center; 
}

.btn-one:hover {
  border: 2px solid #00cc99;
  color: #00cc99;
  transform: scale(1.05);
  background-color: rgba(0, 204, 153, 0.1);
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(246, 242, 242, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 10px;
}

.modal-popup {
  background-color: #0e1726;
  border-radius: 10px;
  width: 90%; 
  max-width: 500px;
  padding: 20px;
  position: relative;
  z-index: 10000;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-header {
  text-align: center;
  background-color: #0e1726;
  color: white;
  font-size: 1.5em;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  margin-bottom: 20px;
}

.modal-body {
  color: #d9d9d9;
  font-size: 1em;
}

.modal-info-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 10px;
}


/*cell phone screens*/
@media (max-width: 768px) {
  .modal-popup {
    width: 100%;
    padding: 15px;
  }

  .modal-header {
    font-size: 1.2em; 
    padding: 8px;
  }

  .modal-body,
  .modal-info-text {
    font-size: 0.9em; 
  }
}

/*small screens*/

@media (max-width: 480px) {
  .modal-popup {
    padding: 10px;
  }

  .modal-header {
    font-size: 1em;
  }

  .modal-body,
  .modal-info-text {
    font-size: 0.8em;
  }
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  color: #9ca3af;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.form-group input {
  width: calc(100% - 10px);
  padding: 10px;
  background-color: #374151;
  border: 1px solid #4b5563;
  border-radius: 8px;
  color: white;
  font-size: 14px;
  outline: none;
}

.form-group input:focus {
  border-color: #10b981;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-open .header--dark,
.modal-open footer {
  opacity: 0;
  pointer-events: none;
}
.form-group input[type="datetime-local"] {
  padding: 10px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #4b5563;
  background-color: #1f2937;
  color: #e5e7eb;
  outline: none;
}

.form-group input[type="datetime-local"]:focus {
  border-color: #00cc99;
  box-shadow: 0px 0px 8px rgba(0, 204, 153, 0.4);
}
.date-picker {
  background-color: #0e1726;
  color: white;
  border: 1px solid #4b5563;
  border-radius: 10px;
}



</style>
